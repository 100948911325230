@use "./label";

.fieldset {
  border: none;
  padding: 0;

  &__legend {
    @extend .label !optional;
    margin: 0 0 1em 0;
  }
}
