@use '../abstracts/mixins' as *;

.banner-image {
  margin-block-end: -12.5%;
  isolation: isolate;
  position: relative;
  z-index: -1;
  height: calc(70vh + 1em);
  max-height: 300px;

  &::after {
    content: '';
    position: absolute;
    background-color: var(--clr-white);
    height: 1em;
    width: 100%;
    bottom: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
}

@include mq(small) {
  .banner-image {
    max-height: 400px;
  }
}

@include mq(medium) {
  .banner-image {
    max-height: none;
  }
}
