.bonuswelt {
  &__before_slide {
    text-align: center;
  }
  .splide__arrow {
    background-color: transparent;
    width: 1em;
    svg {
      fill: var(--clr-primary);
    }
  }
  .bonuswelt_company {
    width: 80%;
  }
  &__button {
    margin: auto;
    width: max-content;
  }
  .splide__pagination {
    position: initial;

    li button {
      &.is-active {
        background-color: var(--clr-primary);
      }
      border: 1px solid var(--clr-primary);
      background-color: var(--clr-white);
      transform: scale(1.4);
      opacity: 1;
      height: 10px;
      width: 10px;
      margin-right: 10px;
    }
  }
  &__button_uebersicht {
    text-align: center;
    margin-top: 30px;
  }
}
