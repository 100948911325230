@use '../abstracts/mixins' as *;

.bonuswelt-detail-page {
  .banner {
    margin-bottom: 0px;
    @include mq(big) {
      margin-bottom: -200px;
    }
  }
  .container {
    margin: auto;
    position: relative;
    background: var(--clr-white);
    border-radius: var(--border-radius, 15px);
  }
  h1 {
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
  }
  hr {
    width: 120%;
    margin-left: -12%;
    border: 1px solid var(--clr-primary);
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .split-container {
    @include mq(medium) {
      display: flex;
      > div {
        width: 100%;
      }
    }
  }
  .company__description {
    margin-bottom: 20px;
  }
  .company__name {
    font-size: var(--fs-500);
    font-weight: bold;
  }
  .company__logo__container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    .company__logo {
      max-width: 400px;
      max-height: 250px;
    }
  }
  .company__details {
    margin-top: 25px;
  }
  .bonuswelt-uebersicht-link {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .bonuswelt-map-container {
    width: 100%;
    margin-top: 30px;
    @include mq(medium) {
      margin-top: 0px;
    }
    .bonuswelt-map {
      margin-top: 40px;
      height: 100%;
    }
  }
  .company__category-item {
    background-color: var(--clr-glowing-blue);
    color: var(--clr-white);
    border-radius: var(--border-radius);
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 6px;
    font-size: var(--fs-400);
  }
}
