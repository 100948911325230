.header {
  position: relative;
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 6px;
    width: 100%;
    background: linear-gradient(
      to right,
      var(--clr-warm-sun),
      var(--clr-green-energy)
    );
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__logo {
    height: 5rem;
    position: relative;
    top: 0.3125rem;
  }

  &__link {
    display: inline-block;
    padding: 0.75rem 1.5rem;
  }
}
