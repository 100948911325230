.select {
  &__trigger {
    all: unset;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: var(--border-radius);
    border: 2px solid var(--clr-primary);
    padding: 0.25rem 1rem 0.25rem 1.25rem;
    background: var(--clr-white);
    gap: 0.5rem;

    & > *:first-child {
      margin-inline: auto;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__viewport {
    padding: 0.5rem;
  }

  &__content {
    overflow: hidden;
    background-color: var(--clr-white);
    border-radius: var(--border-radius);
    border: 2px solid var(--clr-primary);
  }

  &__item {
    all: unset;
    display: flex;
    align-items: center;
    user-select: none;
    position: relative;
    padding: 0 2rem 0 2rem;
    cursor: pointer;

    &[data-disabled] {
      pointer-events: none;
      cursor: not-allowed;

      & > * {
        color: grey;
      }
    }

    &:focus-visible {
      background: var(--clr-light-grey);
    }
  }

  &__item-text {
  }

  &__item-indicator {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    left: 0;
  }

  &__scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-white);
    cursor: default;
  }
}
