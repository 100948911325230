@use '../abstracts/mixins' as *;

.bonuswelt-listing-page {
  .offer_container {
    width: 100%;
    float: left;
    padding: 10px;
    position: relative;

    @include mq(small) {
      width: 50%;
    }
  }
  .filter-bar {
    @include mq(small) {
      display: flex;
      justify-content: space-between;
    }
    .filter {
      margin-top: 10px;
    }
    margin-top: 20px;
    margin-bottom: 40px;
    h3 {
      margin-bottom: 20px;
    }
  }
  .select.container {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .select.container:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader {
    font-size: 10px;
    // margin: 50px auto;
    // text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: var(--clr-primary);
    background: -moz-linear-gradient(
      left,
      var(--clr-primary) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
      left,
      var(--clr-primary) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
      left,
      var(--clr-primary) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
      left,
      var(--clr-primary) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
      to right,
      var(--clr-primary) 10%,
      rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: var(--clr-primary);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader:after {
    background: var(--clr-white);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
