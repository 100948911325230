.accordion {
  --flow-spacer: 1.5rem;
  --padding: 1.5rem;

  overflow: hidden;
  background: var(--clr-lighter-grey);
  border-radius: var(--border-radius);

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 4;
    border-top-right-radius: 4;
  }

  &:last-child {
    border-bottom-left-radius: 4;
    border-bottom-right-radius: 4;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 2px var(--clr-primary);
  }

  &__header {
    all: unset;
    display: flex;
  }

  &__trigger {
    all: unset;
    background-color: transparent;
    padding: var(--padding);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fs-500);
    font-weight: 600;
    line-height: 1.3;
    cursor: pointer;
  }

  &__icon {
    color: var(--clr-primary);
    width: 1.75rem;
    height: 1.75rem;
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    flex-shrink: 0;
    margin-inline-start: 1rem;

    [data-state='open'] & {
      transform: rotate(180deg);
    }
  }

  &__content {
    overflow: hidden;
    font-size: var(--fs-400);
    padding: 0 var(--padding) var(--padding);

    &[data-state='open'] {
      height: auto;
      //animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }

    &[data-state='closed'] {
      height: 0;
      //animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}
