@use '../../abstracts/mixins' as *;
@use '../../utilities/container';

.container-field {
  padding-block: 5rem;
  background: var(--bg-color, transparent);

  border-radius: var(--border-radius);

  & > .container {
    margin: 0 auto;
  }

  & > .streamfield {
    border: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background: transparent;
  }

  &--full-width {
    width: auto;
  }

  &--light-grey {
    --bg-color: var(--clr-lighter-grey);
    border: 1px solid #979797;
  }

  &--light-blue {
    --bg-color: var(--clr-light-blue);
  }

  &--gradient-light-blue-dark-blue {
    --bg-color: linear-gradient(
      to bottom,
      var(--clr-lighter-blue),
      var(--clr-glowing-blue)
    );
  }

  &--gradient-light-blue-white {
    --bg-color: linear-gradient(
      to bottom,
      var(--clr-light-blue),
      var(--clr-white)
    );
  }
}

@include mq(medium) {
  .container-field {
  }
}
