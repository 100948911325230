@use '../../abstracts/mixins' as *;
@use '../../utilities/container';

.streamfield {
  @extend .container !optional;

  position: relative;
  z-index: 0;

  & & {
    padding: 0;
  }

  & &:first-child {
    margin-block-start: 0;
  }

  & &:last-child {
    margin-block-end: 0;
  }
}

@include mq(medium) {
  .streamfield {
    .container {
      width: min(100%, 1200px);
    }
  }
}
